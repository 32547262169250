import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import ProgressBar from 'react-bootstrap/ProgressBar';

import img1 from '../assets/images/img1.jpg';

function AppAbout() {
  const html = 80; //barra de nivell tant per cent
  const responsive = 65;  //barra de nivell tant per cent
  const photoshop = 90;  //barra de nivell tant per cent

  return (
    <section id="about" className="block about-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Equipo</h2>
          <div className="subtitle">sobre nuestro equipo</div>
        </div>
        <Row>
          <Col sm={6}>
            <Image src={img1} />
          </Col>
          <Col sm={6}>
            <p>Nuestros profesionales desarrollan y mantienen los sistemas y aplicaciones, lo que permite mejorar la eficiencia y productividad de nuestros clientes.</p>
            <p>El objetivo común del equipo, es ayudar a obtener resultados para garantizar el maximo rendimiento en el desarrollo y la operatividad de toda la parte tecnológica.</p>
            <div className='progress-block'>
              <h4>Java / Python / Javascript</h4>
              <ProgressBar now={html} label={`${html}%`} />
            </div>
            <div className='progress-block'>
              <h4>Infrastructura / IOT</h4>
              <ProgressBar now={responsive} label={`${responsive}%`} />
            </div>
            <div className='progress-block'>
              <h4>Linux Container/Docker</h4>
              <ProgressBar now={photoshop} label={`${photoshop}%`} />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default AppAbout;