import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const servicesData = [
  {
    id: 1,
    icon: 'fas fa-clone',
    title: 'Ingenieria Frontend',
    description: 'Desarrollamos la parte visual y estructura de un app web, además de mantener y optimizar los interfaces de usuario. Tambien nos ocupamos de los componentes externos y gestionar el flujo de trabajo del software.'
  },
  {
    id: 2,
    icon: 'fas fa-snowflake',
    title: 'Ingenieria Api/Backend',
    description: 'Las API presentan una oportunidad única para que las empresas satisfagan las necesidades de sus clientes en diferentes plataformas. Nuestro equipo desarrolla y adapta cada API a las necesidades de cada cliente.'
  },
  {
    id: 3,
    icon: 'fas fa-plug',
    title: 'VPN Personalizada',
    description: 'Nosotros proporcionamos y configuramos un tunel seguro desde el ordenador del usuario a nuestro DataCenter con un cliente multiplataforma de forma rápida y eficiente, con una encriptación de alto nivel para su seguridad.'
  },
  {
    id: 4,
    icon: 'fas fa-desktop',
    title: 'Linux Container',
    description: 'Los contenedores de Linux se han vuelto extremadamente populares gracias a su facilidad de uso y se han convertido en un aspecto importante de la seguridad informática. Adaptamos esta tecnologia a la necesidad de cada proyecto.'
  },
  {
    id: 5,
    icon: 'fas fa-trophy',
    title: 'Contenedores Docker',
    description: 'Docker es el proyecto de software más conocido de los que ofrecen tecnología de virtualización basada en contenedores. Desarrollamos y adaptamos cada aplicación para crear imagenes y crear cada contenedor completamente personalizado.'
  },
  {
    id: 6,
    icon: 'fas fa-life-ring',
    title: 'Proxmox VM/LXC',
    description: ' Proxmox Virtual Environment es una solución de código abierto para la virtualización empresarial. Su objetivo es ayudarte a optimizar el uso de los recursos ya existentes, minimizar el costo por hardware y el tiempo empleado. Ofrecemos hosting en esta plataforma para tus proyectos.'
  }
]

function AppServices() {
  return (
    <section id="services" className="block services-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Nuestros Servicios</h2>
          <div className="subtitle">servicios que brindamos</div>
        </div>
        <Row>
          {
            servicesData.map(services => {
              return (
                <Col sm={4} className='holder' key={services.id}>
                  <div className="icon">
                    <i className={services.icon}></i>
                  </div>
                  <h3>{services.title}</h3>
                  <p>{services.description}</p>
                </Col>
              );
            })
          }
        </Row>
      </Container>
    </section>
  );
}

export default AppServices;