import Carousel from 'react-bootstrap/Carousel';

var heroData = [
  {
    id: 1,
    image: require('../assets/images/img-hero1.jpg'),
    title: 'Servicios tecnológicos a medida',
    description: 'Durante la ejecución de los proyectos tecnológicos realizamos una rigurosa gestión y seguimiento a fin de ajustarnos a las especificaciones, la planificación y los plazos acordados. ',
    link: 'https://www.google.com'
  },
  {
    id: 2,
    image: require('../assets/images/img-hero2.jpg'),
    title: 'Asesoria tecnológica para tus proyectos',
    description: 'Nuestros servicios en asesoría tecnológica permiten abordar proyectos que necesiten una fuerte personalización de la solución y su adaptación a las particularidades de la empresa.',
    link: 'https://www.facebook.com'
  },
  {
    id: 3,
    image: require('../assets/images/img-hero3.jpg'),
    title: 'Cumplimos con nuestros servicios profesionales',
    description: 'Nuestros especialistas cuentan con al experiencia para poder asesorar al cliente en cualquier demanda tecnológica que se quiera plantear y asi abordar con exito qualquier proyecto tecnológico.',
    link: 'https://www.twitter.com'
  }
]

function AppHero() {
  return (
    <section id="home" className="hero-block">
       <Carousel>
          {
            heroData.map(hero => {
              return (
                <Carousel.Item key={hero.id}>
                  <img
                    className="d-block w-100"
                    src={hero.image}
                    alt={"slide " + hero.id}
                  />
                  <Carousel.Caption>
                    <h2>{hero.title}</h2>
                    <p>{hero.description}</p>
                    <a className="btn btn-primary" href='#services'>Mas Información <i className="fas fa-chevron-right"></i></a>
                  </Carousel.Caption>             
                </Carousel.Item>
              );
            })
          }
      </Carousel>
    </section>
  );
}

export default AppHero;