import Container from 'react-bootstrap/Container';
import React from 'react';
// import { MDBRow, MDBCol, MDBInput, MDBTextArea, MDBBtn } from 'mdb-react-ui-kit';

function AppContact() {
  return (
    <section className='border text-center mb-4'>
      <Container fluid>
      <h3 className='mb-6'>Contactar</h3>
      <div className='row'>
        <div className='col-lg-5'>
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d94189.57366362329!2d1.59846575!3d42.4612664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sca!2sad!4v1702308874739!5m2!1sca!2sad'
            className='h-100 w-100'
            style={{ border: '0' }}
            loading='lazy'
          ></iframe>
        </div>
        <div className='col-lg-5'>
          <form>
            <div className='row'>
              <div className='col-md-6'>
                
              </div>
              <div className='col-md-6'>
                <ul className='list-unstyled'>
                  <li>
                    <i className='fas fa-map-marker-alt fa-2x text-primary'></i>
                    <p>
                      <small>Escaldes, Principat d'Andorra</small>
                    </p>
                  </li>
                  <li>
                    <i className='fas fa-tag fa-2x text-primary'></i>
                    <p>
                      <small>https://tutorials.qdynamic.org</small>
                    </p>
                  </li>
                  <li>
                    <i className='fas fa-envelope fa-2x text-primary'></i>
                    <p>
                      <small>qdynamic.contact@gmail.com</small>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </form>
        </div>
      </div>
      </Container>
    </section>
   
  );
}

export default AppContact;